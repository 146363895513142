import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import { mergeRight } from "ramda";
import { host, endpoints } from "@configmegaMenu.config.ts/megaMenuApi.config";
import { ELandingType } from "@enumssearchHit.enum ";
import { TMegaMenuCard } from "#typesmegaMenu";

export class MegaMenuRepository extends Api {
  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: host }, baseOptions));
  }

  async getJobsByType(type: ELandingType): Promise<TMegaMenuCard[]> {
    return this.get<TMegaMenuCard[]>(endpoints.megaMenu.byType, { params: { type } })
      .then((response) => response.data)
      .catch(() => []);
  }
}

export default new MegaMenuRepository();
